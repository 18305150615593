import { render, staticRenderFns } from "./CustomerDetailContact.vue?vue&type=template&id=ca6ded84&scoped=true"
import script from "./CustomerDetailContact.vue?vue&type=script&lang=js"
export * from "./CustomerDetailContact.vue?vue&type=script&lang=js"
import style0 from "./CustomerDetailContact.vue?vue&type=style&index=0&id=ca6ded84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca6ded84",
  null
  
)

export default component.exports